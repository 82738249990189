<template>
  <div class="body22">
    <div class="back">
      <div v-if="print" class="logo">
        <img :src="$tenant.cloud" alt="" style="vertical-align: middle; width: 12%"/>
        <div style="margin-left: 220px; font-weight: 400; font-size: 25px; display: inline-block; vertical-align: middle">{{$l('', '统计报告')}}</div>
      </div>
      <div v-else class="logo"><img :src="$tenant.bigLogo" alt="" style="width: 227px; height: 34px"/></div>
      <div class="content">
        <p class="printIgnore" style="font-size: 12px;color: #404040;margin: 10px 0 10px 10px">{{$l("","当前位置 > 统计报告")}}</p>
        <vm-search class="printIgnore" :label="$l('','统计周期')">
          <el-date-picker
            v-model="dateRange"
            value-format="yyyy-MM-dd"
            type="monthrange"
            :clearable="false"
            :unlink-panels="true"
            :range-separator="$t('common.to')"
            :start-placeholder="$t('common.startDate')"
            :end-placeholder="$t('common.endDate')"
            :picker-options="pickerOptions"
            :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
          <el-button
            :disabled="loading"
            style="margin-left: 10px"
            type="primary"
            @click="onSubmit">
            {{$l("elevatorNetApply.search", "查询")}}
          </el-button>
        </vm-search>
        <div class="printContent">
          <div style="background-color: #E4F0F4;border-bottom: solid 1px #DCDFE6;">
            <span style="margin: 10px;line-height: 30px;font-weight: 600">
              {{$l('', '统计周期 ') + dateRange[0].split('-')[0] + '.' + dateRange[0].split('-')[1] + ' - ' + dateRange[1].split('-')[0] + '.' + dateRange[1].split('-')[1]}}
            </span>
            <el-button
              class="printIgnore"
              type="primary"
              style="float: right; margin-right: 10px; height: 30px"
              :disabled="loading"
              @click="handlePrint">
              {{$l("", "打印pdf")}}
            </el-button>
          </div>
          <div v-loading="loading">
            <div style="border-bottom: solid 1px #DCDFE6;margin: 10px 30px 10px 30px">
              <span
                style="line-height: 25px;font-weight: 600;font-size: 15px;color: #2B80DA;">{{$l("","电梯信息")}}
              </span>
              <div class="vm-separate">
                <span style="margin-top: 5px">{{$l('', '电梯名称')}}：{{elevator.name}}</span>
                <span style="margin-top: 5px">{{$l('', '电梯编号')}}：{{elevator.no}}</span>
              </div>
              <div class="vm-separate">
                <span style="margin-top: 5px">{{$l('', '梯种')}}：{{$l(elevator.elevatorProductTypeNameEn, elevator.elevatorProductTypeName)}}</span>
                <span style="margin-top: 5px">{{$l('', '设备代码')}}：{{elevator.regNo}}</span>
              </div>
              <div class="vm-separate">
                <span style="margin-top: 5px">{{$l('', '出厂编号')}}：{{elevator.factoryNo}}</span>
                <span style="margin-top: 5px">{{$l('', '使用单位')}}：{{elevator.useUnitName}}</span>
              </div>
            </div>
            <div style="margin: 10px 20px 10px 30px">
              <span
                style="line-height: 25px;font-weight: 600;font-size: 15px;color: #2B80DA;">{{$l("","企业信息")}}
              </span>
              <div class="vm-separate">
                <span style="margin-top: 5px">{{$l('', '制造企业')}}：{{elevator.createComName}}</span>
                <span style="margin-top: 5px">{{$l('', '维保企业')}}：{{elevator.maintComName}}</span>
              </div>
              <div class="vm-separate">
                <span style="margin-top: 5px">{{$l('', '维保工1姓名')}}：{{elevator.maintEmpName}}</span>
                <span style="margin-top: 5px">{{$l('', '维保工1电话')}}：{{elevator.maintEmpPhone}}</span>
              </div>
              <div v-if="elevator.maintEmpName1" class="vm-separate">
                <span style="margin-top: 5px">{{$l('', '维保工2姓名')}}：{{elevator.maintEmpName1}}</span>
                <span style="margin-top: 5px">{{$l('', '维保工2电话')}}：{{elevator.maintEmpPhone1}}</span>
              </div>
            </div>
            <el-descriptions style="margin: 20px 20px 10px 20px" border :column="2" :label-style="{'width': '50%'}">
              <el-descriptions-item :label="$l('', '故障停梯率：')" :span="2">{{Number(stopRate).toFixed(3).toString() + "%"}}</el-descriptions-item>
              <el-descriptions-item :label="$l('', '故障困人率：')" :span="2">{{Number(stuckRate).toFixed(3).toString() + "%"}}</el-descriptions-item>
              <el-descriptions-item v-if="maintStopTime > 0" :label="$l('', '维保停梯时间：')" :span="2">{{maintStopTime}} min</el-descriptions-item>
              <el-descriptions-item :label="$l('', '故障停梯时间：')" :span="2">{{jxStopTime}} min</el-descriptions-item>
              <el-descriptions-item v-if="responseTime > 0" :label="$l('', '救援响应时间：')" :span="2">{{Math.round(responseTime)}} min</el-descriptions-item>
              <el-descriptions-item v-if="rescueTime > 0" :label="$l('', '救援时间：')" :span="2">{{Math.round(rescueTime)}} min</el-descriptions-item>
            </el-descriptions>
            <div
              style="margin: 10px 20px 10px 30px;line-height: 25px;font-weight: 600;font-size: 15px;color: #2B80DA;">
              {{$l("","故障占比")}}
            </div>
            <div v-if="faultType.data.length > 0" style="height: 30vh" class="chartPie">
              <high-charts-pie
                id="faultTypePie"
                :data="faultType.data"
                :colors="faultType.color"
                :data-label="true"
                :show-in-legend="true"
                :label-format="this.$l('','值') + ': {point.y} ' + this.$l('','占比 ') + '{point.percentage:.2f} %'">
              </high-charts-pie>
            </div>
            <div v-else style="color: red; margin: 0 0 10px 40px; font-size: 12px"> *该时段无相关故障类型记录</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer"></div>
    <div v-if="print" class="printFooter">{{exportTime}}</div>
  </div>
</template>

<script>
  import HighChartsPie from "@/views/KanBan/HighChartsPie";

  const moduleName = "elevators";

  export default {
    components: {HighChartsPie},
    data() {
      return {
        copyright: window.config.copyright,
        display1: "block",
        display2: "block",
        display3: "block",
        loading: false,
        print: false,
        dateRange: [new Date().toISOString().split("T").at(0), new Date().toISOString().split("T").at(0)],
        faultType: {data: []},
        exportTime: "",
        jxStopTime: 0,
        maintStopTime: 0,
        rescueTime: 0,
        responseTime: 0,
        stopRate: 0,
        stuckRate: 0,
        elevator: {
          id: 0,
          name: "",
          no: "",
          regNo: "",
          useDate: "",
          factoryNo: "",
          useUnitName: "",
          maintComName: "",
          createComName: "",
          maintEmpName: "",
          maintEmpPhone: "",
          maintEmpName1: "",
          maintEmpPhone1: "",
          elevatorProductTypeName: "",
          elevatorProductTypeNameEn: "",
        },
        pickerOptions: {
          shortcuts: [{
            text: this.$l("","本月"),
            onClick(picker) {
              picker.$emit("pick", [new Date(), new Date()]);
            },
          }, {
            text: this.$l("","今年至今"),
            onClick(picker) {
              const end = new Date();
              const start = new Date(new Date().getFullYear(), 0);
              picker.$emit("pick", [start, end]);
            },
          }, {
            text: this.$l("","最近六个月"),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 6);
              picker.$emit("pick", [start, end]);
            },
          }],
        },
      };
    },
    mounted() {
      this.elevator.id = this.$route.params.elevatorId;
      let param = {
        elevatorId : this.$route.params.elevatorId,
        startTime: new Date().toISOString().split("T").at(0),
        endTime: new Date().toISOString().split("T").at(0),
      };
      this.getData();
      this.getReport(param);
    },
    methods: {
      getData() {
        this.loading = true;
        this.$api.getById(moduleName + "/vo", this.elevator.id).then(res => {
          this.elevator = res.data;
          this.loading = false;
        }).catch((error) => {
          if (error.response) {
            this.loading = false;
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      getReport(param) {
        if (this.elevator.useDate != null) {
          if (Date.parse(this.elevator.useDate) > Date.parse(param.startTime)) {
            param.startTime = this.elevator.useDate;
          }
        }
        this.loading = true;
        this.$http.get(moduleName + "/report", param).then(res => {
          this.faultType.data = this.convertToArr(res.data.faultType);
          if (res.data.jxStopTime != null) {
            this.jxStopTime = res.data.jxStopTime;
          } else {
            this.jxStopTime = 0;
          }
          if (res.data.maintStopTime != null) {
            this.maintStopTime = res.data.maintStopTime;
          } else {
            this.maintStopTime = 0;
          }
          if (res.data.rescueTime != null) {
            this.rescueTime = res.data.rescueTime;
          } else {
            this.rescueTime = 0;
          }
          if (res.data.stopRate != null) {
            this.stopRate = res.data.stopRate;
          } else {
            this.stopRate = 0;
          }
          if (res.data.stuckRate != null) {
            this.stuckRate = res.data.stuckRate;
          } else {
            this.stuckRate = 0;
          }
          if (res.data.responseTime != null) {
            this.responseTime = res.data.responseTime;
          } else {
            this.responseTime = 0;
          }
          this.loading = false;
        }).catch((error) => {
          if (error.response) {
            this.loading = false;
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      onSubmit() {
        let param = {
          elevatorId: this.elevator.id,
          startTime: this.dateRange[0],
          endTime: this.dateRange[1],
        };
        this.getReport(param);
      },
      handlePrint() {
        this.print = true;
        let date = new Date();
        let oldTitle = document.title;
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();
        this.exportTime = this.addZero(year) + "-" + this.addZero(month) + "-" + this.addZero(day) + " " + this.addZero(hours) + ":" + this.addZero(minutes) + ":" + this.addZero(seconds);
        document.title = this.elevator.name + "_" + date.getTime();
        this.$forceUpdate();
        setTimeout(()=> {
          window.print();
          document.title = oldTitle;
          this.print = false;
        }, 500);
      },
      convertToArr(obj) {
        let arr = [];
        obj.forEach((item) => {
          let subArr = [];
          subArr.push(item.name);
          subArr.push(item.total);
          subArr.push(item.name);
          arr.push(subArr);
        });
        return arr;
      },
      addZero(number) {
        return number < 10? ("0" + number) : number;
      },
    },
  };
</script>
<style lang="scss" scoped>

.body22 {
  background-color: #404040;
  height: 100%;
  overflow-y:scroll;
  .back {
    width: 100%;
    .logo {
      width: 1090px;
      background-color: #404040;
      margin: 0 auto;
      padding: 10px 0;
    }
    .content {
      width: 1090px;
      background-color: white;
      margin:0 auto;
      border: solid 1px #DCDFE6;
      padding-bottom: 50px;
      .printContent {
        border: solid 1px #DCDFE6;
        .chartPie {
          width: 720px;
          margin-left: 185px;
        }
      }
    }
  }
  .footer {
    color: #FFF;
    background-color: #404040;
    position: fixed;
    text-align: center;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 100;
  }
  .printFooter {
    position: fixed;
    text-align: right;
    color: #404040;
    font-size: 10px;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 100;
  }
}

span {
  color: #606266;
}

.vm-separate {
  font-size: 12px;
  margin-bottom: 6px;
  margin-left: 6px;
  margin-right: 6px;
}

p {
  margin: 0;
}

.title {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #5a9d11;
  margin-left: -10px;
}

@media print {
  .printIgnore {
    display: none;
  }

  .footer {
    display: none;
  }

  .body22 {
    .back {
      .logo {
        width: 720px;
        padding: 0;
        margin-bottom: 30px;
      }
      .content {
        width: 720px;
        border: none;
        padding-bottom: 0;
        .printContent {
          border: none;
          .chartPie {
            width: 720px;
            margin-left: 0;
          }
        }
      }
    }
  }

  @page {
    size: A4;
  }
}
</style>
